<template>
  <div :class="isLightMode ? 'player-wrapper-container bg-light' :'player-wrapper-container'">
    <div
      v-if="videoRNData.status !== 'A'"
      class="player-wrapper"
      style="height: 25vh; width: 100vw; background-color: #000"
    >
      <v-row
        class="align-center justify-center row text-center"
        style="height: 25vh;"
      >
        <v-col cols="6">
          <h5 style="color: #ffff;">
            {{ videoRNData.title_str }}
          </h5>
          <h6 style="color: #ffff;">
            {{ videoRNData.detail_str }}
          </h6>
        </v-col>
      </v-row>
    </div>
    <div
      v-else
      id="player-wrapper"
      :class="isLightMode ? 'player-wrapper bg-light' :'player-wrapper'"
    >
      <Transition>
        <div
          v-if="showGuide"
          class="user-guide-container"
        >
          <div class="user-guide-description">
            <h4>
              Use your fingers to move the camera around
            </h4>
            <div class="user-guide-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="35pt"
                width="35pt"
                viewBox="0 0 24 24"
              ><title>gesture-swipe</title><path
                fill="#fff"
                d="M20.11,3.89L22,2V7H17L19.08,4.92C18.55,4.23 17.64,3.66 16.36,3.19C15.08,2.72 13.63,2.5 12,2.5C10.38,2.5 8.92,2.72 7.64,3.19C6.36,3.66 5.45,4.23 4.92,4.92L7,7H2V2L3.89,3.89C4.64,3 5.74,2.31 7.2,1.78C8.65,1.25 10.25,1 12,1C13.75,1 15.35,1.25 16.8,1.78C18.26,2.31 19.36,3 20.11,3.89M19.73,16.27V16.45L19,21.7C18.92,22.08 18.76,22.39 18.5,22.64C18.23,22.89 17.91,23 17.53,23H10.73C10.36,23 10,22.86 9.7,22.55L4.73,17.63L5.53,16.83C5.75,16.61 6,16.5 6.33,16.5H6.56L10,17.25V6.5C10,6.11 10.13,5.76 10.43,5.46C10.73,5.16 11.08,5 11.5,5C11.89,5 12.24,5.16 12.54,5.46C12.84,5.76 13,6.11 13,6.5V12.5H13.78C13.88,12.5 14.05,12.55 14.3,12.61L18.84,14.86C19.44,15.14 19.73,15.61 19.73,16.27Z"
              /></svg>
            </div>
          </div>
        </div>
      </Transition>
      <div
        v-show="loading"
        class="loading-container"
      >
        <div

          class="spinner"
        ></div>
      </div>

      <div
        v-if="videoRNData.video_type === 'S' && !manualViewer && !startCreateHighlight"
        id="cams-container"
        class="cams-container"
      >
        <div class="text-center pa-0 ">
          <div
            v-if="camSelected != 1"
            style="flex: .4;"
            class="cam-btn text-center"
            @click="camSelected != 1 && onSelectCam('left')"
          >
            <h5>1</h5>
          </div>
        </div>
        <div
          style="flex: 0.3; background-color: #ffff; color: #3f3f3f; opacity: 50%; max-width: 56px;"
          class="text-center pa-0 cam-btn mx-2"
        >
          <h5 style="margin-top: 6px; font-weight: bolder">
            Cam {{ camSelected }}
          </h5>
        </div>
        <div
          v-if="camSelected != 2"
          cols="2"
          class="text-center pa-0"
        >
          <div
            style="flex: .4;"
            class="cam-btn text-center"
            @click="camSelected != 2 && onSelectCam('Right')"
          >
            <h5>2</h5>
          </div>
        </div>
      </div>

      <div
        id="action-container"
        :class="!startCreateHighlight ? 'actionRow' : 'actionRow-one'"
      >
        <div
          v-if="showCustomControls"
          class="actionsContainer"
        >
          <button
            v-if="!startCreateHighlight && !manualViewer && videoLoaded"
            class="actionBtn actionBtn-success"
            @click="onStartHighlight"
          >
            <h5>Start highlight</h5>
          </button>
          <button
            v-show="videoRNData.stitched_video_url && !startCreateHighlight && showModeBtn && videoRNData.video_type !== 'S'"
            class="actionBtn"
            @click="changeTo()"
          >
            <h5>{{ !manualViewer ? 'Manual Viewer' : 'Tracked Video' }}</h5>
          </button>
          <div
            v-if="startCreateHighlight && !showInputName"
            style="display: flex;
            flex-direction: column;
            align-items: center"
            class="text-center"
          >
            <h5 class="timer text-white mb-2">
              {{ currentTimeVideo < 10 ? '00:0' + currentTimeVideo : '00:' + currentTimeVideo }}
            </h5>
            <button
              v-show="currentTimeVideo > 2"
              class="actionBtn"
              @click="onStopHighlight"
            >
              <h5>Stop highlight</h5>
            </button>
          </div>
          <!--
          <button
            v-if="manualViewer && !startCreateHighlight"
            class="actionBtn"
            @click="changeTo('m')"
          >
            <h6>Video Tracked</h6>
          </button> -->

          <!-- <button
            v-if="!manualViewer && !startCreateHighlight"
            class="actionBtn"
            @click="changeTo('t')"
          >
            <h6>Manual Viewer</h6>
          </button> -->
        </div>
      </div>

      <!-- eslint-disable-next-line vuejs-accessibility/media-has-caption -->
      <video
        id="player"
        :class="isLightMode ? 'player video-js vjs-fluid vjs-layout-large' :'player video-js vjs-fluid vjs-layout-large'"
        crossorigin="anonymous"
        preload
        controls
        muted="muted"
        autoplay
        playsinline
      >

      </video>
    </div>

    <v-card-text
      v-if="!inFullscreen"
      class="flex-row overflow-y-auto highlights-container"
      style="height: calc(67.5dvh + 14px)"
    >
      <h4 :class=" isLightMode ? 'text-white list-item-light px-4 pt-4 font-light1 font-weight-semibold' : 'text-white px-4 pt-4 font-weight-semibold'">
        All Highlight ({{ videoRNData.highlights_count }})
      </h4>
      <v-list :class="isLightMode ? 'bg-light  list-content' :'list-content'">
        <v-list-item
          v-for="(highlight, index) in videoRNData.highlights"
          :key="index"

          :class="isLightMode ? 'px-4 bg-light list-item-light' :'px-4 mb-3'"
        >
          <v-list-item-avatar
            class="avatar-highlight"
            @click="onSelectHighlight(highlight)"
          >
            <v-img :src="highlight.thumbnail"></v-img>
            <v-icon
              size="25"
              style="position: absolute;"
              color="white"
            >
              {{ icons.mdiPlay }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span :class="isLightMode ? 'font-light1 font-weight-semibold' : 'font-weight-semibold'">
                {{ highlight.name }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle>
              <span
                :class="isLightMode ? 'font-light2 font-weight-semibold' : 'font-weight-semibold'"
                style="font-size: 0.750rem;"
              >
                {{ highlight.time_str + ' - ' + highlight.type_str }}
              </span>
            </v-list-item-subtitle>
            <v-row
              v-if="highlight.users && highlight.users.length === 1"
              class="mt-1 pl-3 align-center"
            >
              <v-avatar
                size="20"
                class="mr-1"
              >
                <v-img :src="highlight.users[0].avatar"></v-img>
              </v-avatar>
              <span
                :class="isLightMode ? 'font-light2 font-weight-semibold' : 'font-weight-semibold'"
                style="font-size: 0.67em;"
              >
                {{ highlight.users[0].username }}
              </span>
            </v-row>
            <v-row
              v-else
              class="mt-1 pl-3 align-center"
            >
              <v-avatar
                v-for="(i, item) in highlight.users"
                :key="i"
                size="20"
                class="mr-1"
              >
                <v-img :src="item.avatar"></v-img>
              </v-avatar>
            </v-row>
          </v-list-item-content>
          <v-list-item-action class="flex-row align-center">
            <div
              v-if="!highlight.highlight_url"
              class="col-center"
            >
              <v-progress-circular
                indeterminate
                size="20"
                width="2"
                :color="isLightMode ? '#3f3f3f' : 'light'"
              >
              </v-progress-circular>
              <span
                :class="isLightMode ? 'font-light2 font-weight-semibold mt-2' : 'font-weight-semibold mt-2'"
                style="font-size: 0.67em;"
              >
                Creating
              </span>
            </div>
            <div v-else>
              <!-- <v-icon

                size="30"
                color=""
                @click="removeHighlight(highlight)"
              >
                {{ icons.mdiTrashCanOutline }}
              </v-icon> -->
              <div
                v-if="highlight.can_delete"
                class="col-center"
              >
                <svg
                  height="20pt"
                  width="20pt"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#FF3232"
                  style="transform: scale(1.2);"
                  viewBox="0 0 24 24"
                  @click="onRemoveHighlight(highlight)"
                >
                  <path
                    d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                  />
                </svg>
                <span
                  :class="isLightMode ? 'font-light2 font-weight-semibold mt-2' : 'font-weight-semibold mt-2'"
                  style="font-size: 0.67em;"
                >
                  Delete
                </span>
              </div>
              <div
                v-else
                class="col-center"
              >
                <svg
                  v-if="!highlight.can_delete && !includeVideoID(highlight.id)"
                  height="20pt"
                  width="20pt"
                  viewBox="0 0 512 512"
                  @click="onUnSaveHighlight(highlight.id)"
                >
                  <g
                    transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    :fill="isLightMode ? '#000' : '#ffff'"
                    stroke="none"
                  >
                    <path
                      d="M1365 4672 c-120 -42 -205 -117 -258 -230 l-32 -67 -3 -2038 c-1 -1120 0 -2037 3 -2037 3 0 338 167 745 370 l740 370 740 -370 c407 -203 742 -370 745 -370 3 0 5 268 5 595 l0 595 -210 0 -210 0 0 -250 c0 -137 -2 -250 -5 -250 -3 0 -244 119 -535 265 l-530 265 -530 -265 c-291 -146 -532 -265 -535 -265 -3 0 -4 737 -3 1638 l3 1637 1065 0 1065 0 3 -107 3 -108 210 0 211 0 -4 163 c-3 158 -4 164 -35 229 -44 94 -109 160 -202 206 l-75 37 -1160 2 c-1081 2 -1164 1 -1211 -15z"
                    />
                    <path
                      d="M3840 3200 l0 -210 -212 -2 -213 -3 -3 -212 -2 -213 215 0 215 0 0 -215 0 -215 213 2 212 3 3 213 2 212 210 0 210 0 0 215 0 215 -210 0 -210 0 0 210 0 210 -215 0 -215 0 0 -210z"
                    />
                  </g>
                </svg>
                <svg
                  v-else
                  height="20pt"
                  width="20pt"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#FFBA5C"
                  style="transform: scale(1.1);"
                  viewBox="0 0 24 24"
                  @click="onUnSaveHighlight(highlight.id)"
                >
                  <title>bookmark</title>
                  <path d="M17,3H7A2,2 0 0,0 5,5V21L12,18L19,21V5C19,3.89 18.1,3 17,3Z" />
                </svg>
                <span
                  :class="isLightMode ? 'font-light2 font-weight-semibold mt-2' : 'font-weight-semibold mt-2'"
                  style="font-size: 0.67em;"
                >
                  {{ !includeVideoID(highlight.id) ? 'Save' : 'Saved' }}
                </span>
              </div>
            </div>
            <div
              v-if="highlight.highlight_url"
              class="ms-4 col-center"
              @click="doAction(highlight, 'shared')"
            >
              <svg
                height="20pt"
                width="20pt"
                viewBox="0 0 512 512"
              >
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  :fill="isLightMode ? '#000' : '#ffff'"
                  stroke="none"
                >
                  <path
                    d="M4499 4929 c-20 -4 -60 -18 -90 -30 -30 -11 -949 -300 -2044 -640 -1094 -340 -2007 -626 -2027 -635 -134 -60 -192 -230 -125 -366 16 -32 44 -70 63 -85 18 -16 454 -246 968 -513 l934 -485 488 -938 c338 -649 499 -949 524 -974 68 -70 169 -96 262 -69 70 20 130 64 161 118 14 24 298 921 632 1993 334 1073 625 2006 648 2075 49 150 54 230 18 322 -63 162 -251 266 -412 227z m-431 -569 c2 -22 -129 -157 -891 -918 -492 -490 -902 -892 -911 -892 -9 0 -352 174 -761 387 -648 337 -745 390 -745 410 0 12 6 25 13 30 30 19 3235 1011 3262 1010 24 -2 31 -7 33 -27z m322 -305 c10 -12 -78 -302 -465 -1547 -263 -843 -493 -1581 -512 -1640 -31 -99 -36 -108 -58 -108 -15 0 -30 9 -39 23 -32 51 -766 1470 -766 1480 0 19 1793 1807 1811 1807 9 0 22 -7 29 -15z"
                  />
                </g>
              </svg>
              <span
                :class="isLightMode ? 'font-light2 font-weight-semibold mt-2' : 'font-weight-semibold mt-2'"
                style="font-size: 0.67em;"
              >
                Share
              </span>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
    <!-- dialog create highlight -->
    <v-dialog
      v-model="showInputName"
      :persistent="true"
      :width="'80vw'"
    >
      <v-card class="white pa-5">
        <v-card-title class="text-center justify-center">
          <h4 style="color: #000 !important;">
            Name your highlight
          </h4>
        </v-card-title>
        <v-card-text>
          <div class="inputContainer">
            <v-text-field
              ref="nameInput"
              v-model="highlightName"
              dense
              outlined
              rounded
              :type="'text'"
              :placeholder="'Type the highlight name'"
              :error-messages="errorMessages.required"
              :rules="[validators.required]"
              hide-details
              class="white--text lg-theme mt-2"
            >
            </v-text-field>
            <v-row class="justify-center mt-1">
              <v-col
                cols="12"
                class="text-center"
              >
                <v-btn
                  class="mb-2 py-2 no-bg-hover white--text w-full rounded-pill"
                  style="text-transform: inherit; background-color: #3F3F3F;"
                  @click="onSaveHighlight"
                >
                  {{ 'Save' }}
                </v-btn>
                <v-btn
                  class="mt-1 py-2 no-bg-hover white black--text w-full rounded-pill"
                  style="text-transform: inherit; border: solid 1px black !important"
                  outlined
                  @click="onCancelHighlight"
                >
                  {{ 'Cancel' }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- dialog delete highlight -->
    <v-dialog
      v-model="showDeleteHighlightDialog"
      :persistent="true"
      :width="'80vw'"
    >
      <v-card class="white pa-5">
        <v-card-title class="text-center justify-center">
          <h4 style="color: #000 !important;">
            {{ 'Are you sure?' }}
          </h4>
        </v-card-title>
        <v-card-text>
          <div class="inputContainer mt-4 mb-2">
            <p class="text-description text-center">
              This highlight will be delete.<br />
              No one will be able to access it.
            </p>
            <v-row class="justify-center mt-5">
              <v-col
                cols="12"
                class="text-center"
              >
                <v-btn
                  class="mb-2 py-2 no-bg-hover grey-dark white--text w-full rounded-pill"
                  style="text-transform: inherit; background-color: #3F3F3F;"
                  @click="removeHighlight"
                >
                  {{ 'Remove highlight' }}
                </v-btn>
                <v-btn
                  class="mt-1 py-2 no-bg-hover white black--text w-full rounded-pill"
                  style="text-transform: inherit; border: solid 1px black !important"
                  outlined
                  @click="showDeleteHighlightDialog = false"
                >
                  {{ 'Cancel' }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- dialog unsave highlight -->
    <v-dialog
      v-model="showUnSaveHighlightDialog"
      :persistent="true"
      :width="'80vw'"
    >
      <v-card class="white pa-2 pt-5 pb-5">
        <v-card-title class="text-center justify-center">
          <h4 style="color: #000 !important;">
            {{ 'Are you sure?' }}
          </h4>
        </v-card-title>
        <v-card-text>
          <div class="inputContainer mt-4 mb-2">
            <p class="text-description text-center">
              You will lose access to this highlight.<br />
              It will no longer be visible in your profile.
            </p>
            <v-row class="justify-center mt-5">
              <v-col
                cols="12"
                class="text-center"
              >
                <v-btn
                  class="mb-2 py-2 no-bg-hover grey-dark white--text w-full rounded-pill"
                  style="text-transform: inherit; background-color: #3F3F3F;"
                  @click="addVideoId(highlightSelected)"
                >
                  {{ 'Remove bookmark' }}
                </v-btn>
                <v-btn
                  class="mt-1 py-2 no-bg-hover white black--text w-full rounded-pill"
                  style="text-transform: inherit; border: solid 1px black !important"
                  outlined
                  @click="showUnSaveHighlightDialog = false"
                >
                  {{ 'Cancel' }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable no-lonely-if */
import {
  ref, computed, onMounted,
} from '@vue/composition-api'
import {
  mdiClose, mdiBookmarkOutline, mdiExportVariant, mdiCheckBold, mdiTrashCanOutline, mdiPlay, mdiStop,
} from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import {
  formatCurrency,
  isAndroid,
  isIOS,
  useRouter,
  defaultDownloadAppByOS,
} from '@core/utils'
import themeConfig from '@themeConfig'
import Axios from 'axios'
import { required, emailValidator, minLengthValidator } from '@core/utils/validation'
import RepeatRequest from '@core/utils/repeatRequest.services'
import moment from 'moment'

export default {
  components: {

  },
  setup() {
    const BASE_URL = `${process.env.VUE_APP_API_URL_BACKEND}v1/`

    const repeat = new RepeatRequest()

    const { route } = useRouter()
    const { params, query } = route.value

    const { t } = useUtils()
    const startCreateHighlight = ref(false)
    const showInputName = ref(false)
    const highlightName = ref('')
    const currentTimeVideo = ref(1)
    const camSelected = ref(1)
    const highlightSelected = ref(0)
    const actionBtnsDom = ref(null)
    const camsContainerDom = ref(null)
    const newHighlight = ref({
      user_id: '',
      name: '',
      creation_date: moment().format('YYYY-MM-DD'),
      highlight_type: 'H',
      start_time: 0,
      end_time: 0,
      video_id: 0,
      recording_id: 0,
    })
    const manualViewer = ref(false)
    const videoRNData = ref({
      highlights: [],
      detail_str: '',
      status: 'A',
      status_str: '',
      highlights_times: [],
    })
    const listFolder = ref([])
    const errorMessages = ref([])
    const showCustomControls = ref(true)
    const myPlaylist = ref([])
    const inFullscreen = ref(false)
    const showModeBtn = ref(false)
    const videoLoaded = ref(false)
    const playerDuration = ref(1)
    const touchDuration = ref(0)
    const isLightMode = ref(false)
    const showGuide = ref(false)
    const loading = ref(true)
    const showDeleteHighlightDialog = ref(false)
    const showUnSaveHighlightDialog = ref(false)
    const isAndroidComputed = computed(() => isAndroid())
    const isIOSComputed = computed(() => isIOS())
    let player = null
    let timeoutHighlight = null
    let intervalHighlight = null
    let modeBtnTime = null
    let touchInterval = null
    let touchTimeout = null
    function restartPlayer() {
      loading.value = true
      player.dispose()
      const elplayer = document.createElement('video')
      elplayer.setAttribute('id', 'player')
      elplayer.setAttribute('controls', 'player')
      elplayer.setAttribute('crossorigin', 'anonymous')
      elplayer.setAttribute('preload', 'metadata')
      elplayer.setAttribute('muted', 'muted')
      elplayer.setAttribute('autoplay', 'true')
      elplayer.setAttribute('playsinline', 'playsinline')
      elplayer.classList.add('video-js')
      elplayer.classList.add('player')
      elplayer.classList.add('vjs-fluid')

      // if (isLightMode.value) {
      //   elplayer.classList.add('bg-light')
      // } else {
      //   elplayer.classList.remove('bg-light')
      // }

      const wrapper = document.getElementById('player-wrapper')
      wrapper.appendChild(elplayer)
      player = window.videojs('player', {
        disablePictureInPicture: true,
        aspectRatio: '16:9',
        controlBar: {
          fullscreenToggle: false,
        },
        userActions: {
          doubleClick: false,
        },
      })

      window.removeEventListener('deviceorientation', e => console.log(e))
    }

    async function sendUserActivities(videoId = null, highlightId = null, errorCode = null) {
      const metadata = {
        errorCode,
        osName: query?.osName,
        osVersion: query?.osVersion,
        modelId: query?.modelId,
        modelName: query?.modelName,
      }

      const axions = new Axios.Axios({})
      try {
        await axions.post(
          `${BASE_URL}user/activity/`,
          JSON.stringify(
            {
              user_id: query?.userId,
              video_id: videoId,
              highlight_id: highlightId,
              action: 'Video Player Error',
              meta_data: metadata,
            },
          ),
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Accept-Language': 'es',
            },
          },
        )
      } catch (error) {
        console.log(error)
      }
    }

    async function sendUserActivityInManualViewer(videoId = null, highlightId = null) {
      const axions = new Axios.Axios({})
      try {
        await axions.post(
          `${BASE_URL}user/activity/`,
          JSON.stringify(
            {
              user_id: query?.userId,
              video_id: videoId,
              highlight_id: highlightId,
              action: 'View Video App 180',
            },
          ),
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Accept-Language': 'es',
            },
          },
        )
      } catch (error) {
        console.log(error)
      }
    }
    function setManualViewerVideo(currentTime) {
      try {
        const highlightTime = videoRNData.value.highlights_times.map((timeh, ih) => ({
          time: timeh, text: String(timeh), overlayText: String(ih),
        }))
        player.src({ type: 'video/mp4', src: videoRNData.value.stitched_video_url })
        // eslint-disable-next-line no-use-before-define
        if (videoRNData.value.stitched_video_url) {
          // eslint-disable-next-line no-use-before-define
          generateBarManualViewerBtn(player, 'vjs-icon-cam')
        }
        player.nuevo({
          shareMenu: false,
          zoomMenu: false,
          contextMenu: false,
          relatedMenu: false,
          rateMenu: false,
          settingsButton: false,
        })

        if (!player.mediainfo) {
          player.mediainfo = {}
        }

        if (!player.mediainfo.projection) {
          player.mediainfo.projection = '360'
        }

        player.vr({ projection: '360' })

        player.load()
        player.ready(() => {
          player.currentTime(currentTime)

          setTimeout(() => {
            loading.value = false
            // eslint-disable-next-line no-use-before-define
            setPassGuide()
          }, 6000)
        })
        window.addEventListener('deviceorientation', event => {
          // Rotación alrededor del eje Z (0 a 360 grados)
          const { beta } = event // Inclinación frontal/trasera del dispositivo (-180 a 180 grados)
          if (beta > 80) {
            // window.alert(`beta ${beta}`)
            player.vr().camera.position.set(player.vr().camera.position.x, 0, player.vr().camera.position.z)
            player.vr().controls3d.update()
          }

          /*      if (beta <= 0) {
            // window.alert(`beta ${beta}`)
            player.vr().camera.position.set(player.vr().camera.position.x, 0, player.vr().camera.position.z)
            player.vr().controls3d.update()
          } */
        })
        player.on('loadedmetadata', () => {
          if (videoRNData.value.stitched_video_url && (videoRNData.value.left_video_url || videoRNData.value.video_url)) {
            // eslint-disable-next-line no-use-before-define
            generateBarManualViewerBtn(player, 'vjs-icon-cam')
            // eslint-disable-next-line no-use-before-define
            generateFullScreenBtn(player, inFullscreen.value ? 'vjs-icon-fsExit' : 'vjs-icon-fs', 12)
          } else {
            // eslint-disable-next-line no-use-before-define
            generateFullScreenBtn(player, inFullscreen.value ? 'vjs-icon-fsExit' : 'vjs-icon-fs', 11)
          }
          // eslint-disable-next-line no-use-before-define
          addBtnActionsInsidePlayer(actionBtnsDom.value, camsContainerDom.value)
          // eslint-disable-next-line no-use-before-define
          generateRateBtn(player)
          // eslint-disable-next-line no-use-before-define
          setHighlightMarks(player, highlightTime)
          document.getElementById('player-wrapper').click()
          sendUserActivityInManualViewer(params.user)

          // const timer = setInterval(() => {
          //   if (player.vr().camera) {
          //     clearInterval(timer)
          setTimeout(() => {
            player.vr().camera.position.set(0, 0, 0)
            player.vr().controls3d.update()
          }, 1000)

          //   }
          // }, 1200)
        })

        player.on('touchstart', e => {
          console.log(e)

          document.querySelectorAll('.vjs-touch-nav')[0].classList.remove('touch-show')
          clearInterval(touchInterval)
          touchInterval = setInterval(() => {
            touchDuration.value += 1
          }, 10)
        })

        player.on('touchend', () => {
          clearInterval(touchInterval)
          console.log('touchDuration', touchDuration.value)
          if (touchDuration.value < 50) {
            showModeBtn.value = true
            clearTimeout(modeBtnTime)
            clearTimeout(touchTimeout)
            document.querySelectorAll('.vjs-touch-nav')[0].classList.add('touch-show')
            touchTimeout = setTimeout(() => {
              showModeBtn.value = false
              document.querySelectorAll('.vjs-touch-nav')[0].classList.remove('touch-show')
            }, 4000)
          }
          touchDuration.value = 0
        })

        player.on('error', () => {
          console.log('error', player.error())
          sendUserActivities(params.user, null, player.error())
        })
      } catch (error) {
        console.log('🚀 ~ setManualViewerVideo ~ error:', error)
      }
    }

    function setTrackedVideo(currentTime) {
      const highlightTime = videoRNData.value.highlights_times.map((timeh, ih) => ({
        time: timeh, text: String(timeh), overlayText: String(ih),
      }))
      player.nuevo({
        playlistUI: false,
        playlistShow: false,
        playlistAutoHide: false,
        playlistNavigation: false,
        playlistRepeat: false,
        shareMenu: false,
        settingsButton: false,
      })
      if (videoRNData.value.video_type === 'S') {
        player.thumbnails({ responsive: true })
        myPlaylist.value = [
          {
            sources: [{ src: videoRNData.value.left_video_url || videoRNData.value.video_url, type: 'video/mp4' }],
            thumb: '',
            slideImage: '',
            title: 'video',
            duration: '01:13',
          },
          {
            sources: [{ src: videoRNData.value.right_video_url, type: 'video/mp4' }],
            thumb: '',
            slideImage: '',
            title: 'video',
            duration: '01:13',
          },
        ]

        // player.playlist(myplaylist)
      }
      if (videoRNData.value.stitched_video_url) {
        // eslint-disable-next-line no-use-before-define
        generateBarManualViewerBtn(player, 'vjs-icon-vr')
      }
      player.src({ type: 'video/mp4', src: videoRNData.value.left_video_url || videoRNData.value.video_url })

      player.load()
      player.ready(() => {
        player.currentTime(currentTime)
      })
      player.on('loadedmetadata', () => {
        if (videoRNData.value.stitched_video_url && (videoRNData.value.left_video_url || videoRNData.value.video_url)) {
          // eslint-disable-next-line no-use-before-define
          generateBarManualViewerBtn(player)
          // eslint-disable-next-line no-use-before-define
          generateFullScreenBtn(player, inFullscreen.value ? 'vjs-icon-fsExit' : 'vjs-icon-fs', 12)
        } else {
          // eslint-disable-next-line no-use-before-define
          generateFullScreenBtn(player, inFullscreen.value ? 'vjs-icon-fsExit' : 'vjs-icon-fs', 11)
        }
        // eslint-disable-next-line no-use-before-define
        generateRateBtn(player)

        // eslint-disable-next-line no-use-before-define
        setHighlightMarks(player, highlightTime)
        setTimeout(() => {
          // eslint-disable-next-line no-use-before-define
          addBtnActionsInsidePlayer(actionBtnsDom.value, camsContainerDom.value)
        }, 1000)
        loading.value = false
      })
      player.on('touchstart', e => {
        if (String(e.target.id).includes('player_html5_api')) {
          showModeBtn.value = !showModeBtn.value
          clearTimeout(modeBtnTime)
          showModeBtn.value = true
          modeBtnTime = setTimeout(() => {
            showModeBtn.value = false
          }, 4000)
        }
      })

      player.on('error', () => {
        console.log('error', player.error())
        sendUserActivities(params.user, null, player.error())
      })
    }

    function changeTo() {
      const currentTime = player.currentTime()
      restartPlayer()
      if (!manualViewer.value) {
        manualViewer.value = true
        setManualViewerVideo(currentTime, videoRNData)
        // eslint-disable-next-line no-use-before-define
        // eslint-disable-next-line no-unused-expressions
        typeof DeviceMotionEvent !== 'undefined'
                && typeof DeviceMotionEvent.requestPermission === 'function'
                && DeviceMotionEvent.requestPermission()
                  .then(ev => {
                    // eslint-disable-next-line no-unused-expressions
                    ev === 'granted' && window.addEventListener('devicemotion', e => { console.log(e) })
                  })
                  .catch(console.error)
      } else {
        manualViewer.value = false
        setTrackedVideo(currentTime, videoRNData)
      }
    }

    function generateBarManualViewerBtn(elplayer, className = 'vjs-icon-vr') {
      player.getChild('controlBar').removeChild('vrbtn', {}, 8)
      const btn = window.videojs.getComponent('Button')
      const vrBtn = window.videojs.extend(btn, {
        constructor() {
          // eslint-disable-next-line prefer-rest-params
          btn.apply(this, arguments)
          this.addClass(className)
        },
        handleClick() {
          changeTo()
        },
      })

      window.videojs.registerComponent('vrbtn', vrBtn)
      elplayer.getChild('controlBar').addChild('vrbtn', {}, 8)
    }

    function ingreseRate() {
      const currentRate = player.playbackRate()
      player.getChild('controlBar').removeChild('rateBtn', {}, 9)
      if (currentRate === 1) {
        player.playbackRate(2)
        // eslint-disable-next-line no-use-before-define
        generateRateBtn(player, 'vjs-icon-2x')
      } else {
        player.playbackRate(1)
        // eslint-disable-next-line no-use-before-define
        generateRateBtn(player, 'vjs-icon-1x')
      }
    }

    function generateRateBtn(elplayer, className = 'vjs-icon-1x') {
      elplayer.getChild('controlBar').removeChild('rateBtn', {}, 9)
      const btn = window.videojs.getComponent('Button')
      const rateBtn = window.videojs.extend(btn, {
        constructor() {
          // eslint-disable-next-line prefer-rest-params
          btn.apply(this, arguments)
          this.addClass(className)
        },
        handleClick() {
          ingreseRate()
        },
      })

      window.videojs.registerComponent('rateBtn', rateBtn)
      elplayer.getChild('controlBar').addChild('rateBtn', {}, 9)
    }

    function setFullScreen(elPlayer) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('fullscreenchange')
      }

      if (inFullscreen.value) {
        // eslint-disable-next-line no-use-before-define
        generateFullScreenBtn(elPlayer)
      } else {
        // eslint-disable-next-line no-use-before-define
        generateFullScreenBtn(elPlayer, 'vjs-icon-fsExit')
      }
      inFullscreen.value = !inFullscreen.value
    }

    function generateFullScreenBtn(elplayer, className = 'vjs-icon-fs', position = 11) {
      player.getChild('controlBar').removeChild('fsBtn', {}, position)
      const btn = window.videojs.getComponent('Button')
      const fsBtn = window.videojs.extend(btn, {
        constructor() {
          // eslint-disable-next-line prefer-rest-params
          btn.apply(this, arguments)
          this.addClass(className)
        },
        handleClick() {
          setFullScreen(elplayer)
        },
      })

      window.videojs.registerComponent('fsBtn', fsBtn)
      elplayer.getChild('controlBar').addChild('fsBtn', {}, position)
    }

    // function generateRateBtn(elplayer, className = 'vjs-icon-rate') {
    //   const rate = elplayer.playbackRate()
    //   const btn = window.videojs.getComponent('Button')
    //   const vrBtn = window.videojs.extend(btn, {
    //     constructor() {
    //       // eslint-disable-next-line prefer-rest-params
    //       btn.apply(this, arguments)
    //       this.addClass(className)
    //       this.controlText(`${rate}X`)
    //     },
    //     handleClick() {
    //       elplayer.playbackRate(rate === 1 ? 2 : 1)
    //     },
    //   })

    //   window.videojs.registerComponent('vrbtn', vrBtn)
    //   elplayer.getChild('controlBar').addChild('vrbtn', {}, 9)
    // }

    function playHighlightLoop(startTime, endTime, currentTime, elplayer, loop = false) {
      if (currentTime >= endTime && loop) {
        elplayer.currentTime(startTime)
      }
    }

    function onStopHighlight() {
      clearTimeout(timeoutHighlight)
      clearInterval(intervalHighlight)
      showInputName.value = true
      newHighlight.value = {
        ...newHighlight.value,
        end_time: player.currentTime(),
      }
      player.currentTime(newHighlight.value.start_time)
      player.on('timeupdate', () => {
        const current = player.currentTime()
        playHighlightLoop(newHighlight.value.start_time, newHighlight.value.end_time, current, player, true)
      })
    }

    function onStartHighlight() {
      const p = document.getElementsByClassName('vjs-touch-nav')
      p[0].style = 'display: none'
      player.play()
      startCreateHighlight.value = true
      newHighlight.value = {
        name: '',
        start_time: player.currentTime(),
      }
      player.controls(false)
      timeoutHighlight = setInterval(() => {
        onStopHighlight()
      }, 30000)
      let elcurrentTime = 1
      intervalHighlight = setInterval(() => {
        elcurrentTime += 1
        currentTimeVideo.value = Math.floor(elcurrentTime)
      }, 1000)
    }

    function onSelectHighlight(item) {
      console.log(item)

      // if (highlightSelected.value === item.id) {
      //   player.currentTime(item.start_second)
      // } else {
      highlightSelected.value = item.id
      if (item?.side === 'L') {
        if (camSelected.value === 1) {
          player.currentTime(item.start_second)
        } else {
          // eslint-disable-next-line no-use-before-define
          onSelectCam('left', item.start_second)
        }
      } else if (item?.side === 'R') {
        if (camSelected.value === 2) {
          player.currentTime(item.start_second)
        } else {
          // eslint-disable-next-line no-use-before-define
          onSelectCam('right', item.start_second)
        }
      } else {
        player.currentTime(item.start_second)
      }

      // }
    }

    async function getRecordingDetailBy() {
      const axions = new Axios.Axios({})
      try {
        // const token = 'yJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZXN0MDEiLCJpYXQiOjE3MjY1NzUwNzMsIm5iZiI6MTcyNjU3NTA3MywianRpIjoiYWYxNmZlZjAtZGIxMC00Njc5LWJjMGUtMzhkNjVjNzQxMzY0IiwiZXhwIjoxNzI2NTc1OTczLCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlLCJzY29wZXMiOlsibW9iaWxlX3VzZXIiXX0.wcrUvilQlz-5WFgVvo9YgLG3v6JdrOOxCgpCERQkZrg'
        const { data } = await axions.get(`${BASE_URL}recording/mobile/${params.user}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': 'es',
            'el-token': `Bearer ${params.hash}`,
          },
        })
        const videoInfo = JSON.parse(data)

        if (videoInfo.ok) {
          videoRNData.value = { ...videoInfo.data }
          // eslint-disable-next-line no-use-before-define
          checkHighlightStatus(videoInfo.data.highlights)
          const highlightTime = videoInfo.data.highlights_times.map((timeh, ih) => ({
            time: timeh, text: String(timeh), overlayText: String(ih),
          }))
          // eslint-disable-next-line no-use-before-define
          addMarkers(highlightTime)
        }
      } catch (error) {
        console.log('🚀 ~ getRecordingDetailBy ~ error:', error)
      }
    }

    async function getVideoDetailby() {
      const userId = query.userId || ''
      const axions = new Axios.Axios({})
      try {
        const { data } = await axions.get(`${BASE_URL}highlight/video/mobile/${params.user}/${userId}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': 'es',
            'el-token': `Bearer ${params.hash}`,
          },
        })
        const videoInfo = JSON.parse(data)

        if (videoInfo.ok) {
          videoRNData.value = { ...videoInfo.data }
          // eslint-disable-next-line no-use-before-define
          checkHighlightStatus(videoInfo.data.highlights)
          const highlightTime = videoInfo.data.highlights_times.map((timeh, ih) => ({
            time: timeh, text: String(timeh), overlayText: String(ih),
          }))
          // eslint-disable-next-line no-use-before-define
          addMarkers(highlightTime)
        }
      } catch (error) {
        console.log('🚀 ~ getRecordingDetailBy ~ error:', error)
      }
    }
    const reloadHighlight = async () => {
      // eslint-disable-next-line no-use-before-define
      removeMarkers()
      if (query.entity === 'recording') {
        await getRecordingDetailBy(params.user)
      } else {
        await getVideoDetailby(params.user)
      }
    }

    function checkHighlightStatus(highlights = []) {
      const highlightNotUrl = highlights.find(hightlight => hightlight?.highlight_url == null)
      if (highlightNotUrl) {
        repeat.stopInterval()
        repeat.repeat(5000, reloadHighlight)
      } else {
        repeat.stopInterval()
      }
    }

    async function requestCreateHighlight(body) {
      const axions = new Axios.Axios({})
      const strdata = JSON.stringify(body)
      try {
        const { data } = await axions.post(`${BASE_URL}highlight/`, strdata, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': 'es',
            'el-token': `Bearer ${params.hash}`,
          },
        })
        const videoInfo = JSON.parse(data)

        if (videoInfo.ok) {
          if (query.entity === 'recording') {
            getRecordingDetailBy(params.user)
          } else {
            getVideoDetailby(params.user)
          }
        }
      } catch (error) {
        console.log('🚀 ~ getRecordingDetailBy ~ error:', error)
      } finally {
        currentTimeVideo.value = 0
        highlightName.value = ''
        newHighlight.value = {
          user_id: '',
          name: '',
          creation_date: moment().format('YYYY-MM-DD'),
          highlight_type: 'H',
          start_time: 0,
          end_time: 0,
          video_id: 0,
          recording_id: 0,
        }
      }
    }

    function onCreateHighlight() {
      player.controls(true)
      startCreateHighlight.value = false
      showInputName.value = false
      const currentTime = player.currentTime()
      restartPlayer()
      setTrackedVideo(currentTime)
      const body = {
        ...newHighlight.value,
        name: highlightName.value,
        user_id: query.userId || '',
        creation_date: moment().format('YYYY-MM-DD'),
        video_id: query?.entity === 'recording' ? null : params.user,
        recording_id: query?.entity === 'recording' ? params.user : null,
        highlight_type: 'H',
      }
      if (videoRNData.value.video_type === 'S') {
        Object.assign(body, { side: camSelected.value === 1 ? 'L' : 'R' })
      } else {
        Object.assign(body, { side: null })
      }
      requestCreateHighlight(body)
    }

    function doAction(item, action = 'highlight') {
      const data = JSON.stringify({ action, item })
      window.ReactNativeWebView.postMessage(data)
    }

    function getFolders() {
      const userId = query.userId || ''
      const axions = new Axios.Axios({})
      axions.get(`${BASE_URL}user/videos_folder/${userId}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': 'es',
          'el-token': `Bearer ${params.hash}`,
        },
      })
        .then(response => {
          const resp = JSON.parse(response.data)
          if (Array.isArray(resp.data)) listFolder.value = [...resp.data]
        })
        .catch(error => {
          console.log(error)
        })
    }

    function onUnSaveHighlight(highlightId) {
      console.log('highlightId', highlightId)
      // eslint-disable-next-line no-use-before-define
      if (includeVideoID(highlightId)) {
        showUnSaveHighlightDialog.value = true
        highlightSelected.value = highlightId
      } else {
        // eslint-disable-next-line no-use-before-define
        addVideoId(highlightId)
      }
    }

    function onRemoveHighlight(item) {
      highlightSelected.value = item
      showDeleteHighlightDialog.value = true
    }

    async function removeHighlight() {
      try {
        showDeleteHighlightDialog.value = false
        const userId = query.userId || ''
        const axions = new Axios.Axios({})
        await axions.delete(`${BASE_URL}highlight/${highlightSelected.value.id}/${userId}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': 'es',
            'el-token': `Bearer ${params.hash}`,
          },
        })
        reloadHighlight()
        setTimeout(() => {
          reloadHighlight()
        }, 5000)
      } catch (error) {
        if (error?.data && error.data.message) {
          // showToast(error.data.message, 'error')
        }
      }
    }

    function updateFolders() {
      const userId = query.userId || ''
      const axions = new Axios.Axios({})
      const strdata = JSON.stringify({ videos_folders: listFolder.value })
      axions.put(`${BASE_URL}user/videos_folder/${userId}`, strdata, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': 'es',
          'el-token': `Bearer ${params.hash}`,
        },
      })
        .then(response => {
          console.log('update', response)
          getFolders()
        })
        .catch(error => {
          console.log(error)
        })
    }

    function includeVideoID(videoId) {
      if (listFolder.value.length) return listFolder.value[0].videos_ids.includes(videoId)

      return false
    }

    function addVideoId(videoId) {
      const newlist = listFolder.value[0]
      console.log(videoId)
      if (includeVideoID(videoId)) {
        newlist.videos_ids = newlist.videos_ids.filter(i => i !== videoId)
        console.log('filter', newlist)
      } else {
        newlist.videos_ids.push(videoId)
      }
      showUnSaveHighlightDialog.value = false
      listFolder.value = [newlist]
      setTimeout(() => {
        updateFolders()
      }, 1000)
    }
    function onCancelHighlight() {
      player.controls(true)
      startCreateHighlight.value = false
      showInputName.value = false
      currentTimeVideo.value = 0
      newHighlight.value = {
        user_id: '',
        name: '',
        creation_date: moment().format('YYYY-MM-DD'),
        highlight_type: 'H',
        start_time: 0,
        end_time: 0,
        video_id: 0,
        recording_id: 0,
      }
      const currentTime = player.currentTime()
      restartPlayer()
      setTrackedVideo(currentTime)
    }

    function setHighlightMarks(elplayer, highlightTime = []) {
      const total = elplayer.duration()
      const p = document.getElementsByClassName('vjs-progress-holder vjs-slider vjs-slider-horizontal')

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < highlightTime.length; i++) {
        // eslint-disable-next-line no-mixed-operators
        const left = `${highlightTime[i].time / total * 100}%`

        const { time } = highlightTime[i]
        const el = document.createElement('div')
        el.setAttribute('style', `left: ${left}`)
        el.setAttribute('data-time', `${time}`)
        el.classList.add('js-marker')
        p[0].append(el)
      }
    }

    function onSelectCam(mode, currentTime = null) {
      let elCurrentTime = 0
      if (currentTime != null) {
        elCurrentTime = currentTime
      } else {
        elCurrentTime = player.currentTime()
      }
      restartPlayer()
      console.log('side', 'right')
      if (mode === 'left') {
        camSelected.value = 1

        player.src(videoRNData.value.left_video_url)
        player.currentTime(elCurrentTime)
      } else {
        player.src(videoRNData.value.right_video_url)
        player.currentTime(elCurrentTime)
        camSelected.value = 2
      }
      const highlightTime = videoRNData.value.highlights_times.map((timeh, ih) => ({
        time: timeh, text: String(timeh), overlayText: String(ih),
      }))
      player.nuevo({
        shareMenu: false,
        zoomMenu: false,
        contextMenu: false,
        relatedMenu: false,
        rateMenu: false,
        settingsButton: false,
      })
      player.on('loadedmetadata', () => {
        setHighlightMarks(player, highlightTime)
        if (videoRNData.value.stitched_video_url && (videoRNData.value.left_video_url || videoRNData.value.video_url)) {
          generateBarManualViewerBtn(player)
        }
        generateRateBtn(player)
        generateFullScreenBtn(player, !inFullscreen.value ? 'vjs-icon-fs' : 'vjs-icon-fsExit', 12)

        // eslint-disable-next-line no-use-before-define
        addBtnActionsInsidePlayer(actionBtnsDom.value, camsContainerDom.value)
        if (!inFullscreen.value) {
          // eslint-disable-next-line no-use-before-define
          generateFullScreenBtn(player)
          document.getElementsByClassName('vjs-control-bar')[0].classList.add('fs-bar')
        } else {
          // eslint-disable-next-line no-use-before-define
          generateFullScreenBtn(player, 'vjs-icon-fsExit')
          document.getElementsByClassName('vjs-control-bar')[0].classList.remove('fs-bar')
        }
        player.muted(false)
        loading.value = false
      })

      player.on('touchstart', e => {
        if (String(e.target.id).includes('player_html5_api')) {
          console.log(e)
          showModeBtn.value = !showModeBtn.value
          clearTimeout(modeBtnTime)
          showModeBtn.value = true
          modeBtnTime = setTimeout(() => {
            showModeBtn.value = false
          }, 4000)
        }
      })

      player.on('error', () => {
        console.log('error', player.error())
        sendUserActivities(params.user, null, player.error())
      })
    }

    function addBtnActionsInsidePlayer(actions, cams) {
      document.querySelectorAll('.player')[0].appendChild(actions)
      if (cams) {
        document.querySelectorAll('.player')[0].appendChild(cams)
      }
      if (document.querySelectorAll('.vjs-time-divider')[0]) {
        document.querySelectorAll('.vjs-time-divider')[0].classList.remove('vjs-time-control')
      }
    }

    function removeMarkers() {
      const markers = document.getElementsByClassName('js-marker')
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < markers.length; index++) {
        const element = markers[index]
        element.remove()
      }
    }

    function addMarkers(highlightTime = []) {
      // eslint-disable-next-line no-underscore-dangle
      const p = document.getElementsByClassName('vjs-progress-holder vjs-slider vjs-slider-horizontal')
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < highlightTime.length; i++) {
        // eslint-disable-next-line no-mixed-operators
        const left = `${highlightTime[i].time / playerDuration.value * 100}%`

        const { time } = highlightTime[i]
        const el = document.createElement('div')
        el.setAttribute('style', `left: ${left}`)
        el.setAttribute('data-time', `${time}`)
        el.classList.add('js-marker')
        p[0].append(el)
      }
    }

    // function permissionDeviceMotionEvent() {
    //   if (typeof (DeviceMotionEvent) !== 'undefined' && typeof (DeviceMotionEvent.requestPermission) === 'function') {
    //     DeviceMotionEvent.requestPermission()
    //       .then(response => {
    //         if (response === 'granted') {
    //           const currentTime = player.currentTime()
    //           restartPlayer()
    //           if (manualViewer.value) {
    //             setManualViewerVideo(currentTime, videoRNData)
    //           } else {
    //             setTrackedVideo(currentTime, videoRNData)
    //           }
    //         }
    //       })
    //       .catch(console.error)
    //   } else {
    //     console.log('DeviceMotionEvent', 'No available')
    //   }
    // }
    function setPassGuide() {
      const passGuide = query?.showGuide
      if (passGuide === 'show') {
        showGuide.value = true
        setTimeout(() => {
          showGuide.value = false
        }, 3000)
      }
    }
    onMounted(async () => {
      if (query?.theme === 'light') {
        isLightMode.value = true
      }
      await reloadHighlight()
      getFolders()

      // permissionDeviceMotionEvent()
      const videoInfo = videoRNData.value
      videoRNData.value = { ...videoInfo }
      actionBtnsDom.value = document.getElementById('action-container')
      camsContainerDom.value = document.getElementById('cams-container')
      const highlightTime = videoInfo.highlights_times.map((timeh, ih) => ({
        time: timeh, text: String(timeh), overlayText: String(ih),
      }))
      player = window.videojs('player', {
        disablePictureInPicture: true,
        aspectRatio: '16:9',
        controlBar: {
          fullscreenToggle: false,
        },
        userActions: {
          doubleClick: false,
        },
      })

      player.nuevo({
        playlistUI: false,
        playlistShow: false,
        playlistAutoHide: false,
        playlistNavigation: false,
        playlistRepeat: false,
        shareMenu: false,
        settingsButton: false,
      })
      if (!videoInfo.video_url && !videoInfo.left_video_url) {
        manualViewer.value = true
        showCustomControls.value = false
        if (!player.mediainfo) {
          player.mediainfo = {}
        }

        if (!player.mediainfo.projection) {
          player.mediainfo.projection = '360'
        }

        player.vr({ projection: 'AUTO' })
        player.src({ type: 'video/mp4', src: videoInfo.stitched_video_url })
        sendUserActivityInManualViewer(params.user)
        window.addEventListener('deviceorientation', event => {
          // Rotación alrededor del eje Z (0 a 360 grados)
          const { beta } = event // Inclinación frontal/trasera del dispositivo (-180 a 180 grados)
          if (beta > 89) {
            // window.alert(`beta ${beta}`)
            player.vr().camera.position.set(player.vr().camera.position.x, 0, player.vr().camera.position.z)
            player.vr().controls3d.update()
          }
        })
      } else {
        player.src({ type: 'video/mp4', src: videoInfo.left_video_url || videoInfo.video_url })
      }
      player.ready(() => {
        const spinner = document.getElementsByClassName('vjs-loading-spinner')[0]
        spinner.setAttribute('style', 'display: block')
        if (manualViewer.value) {
          setTimeout(() => {
            loading.value = false
            // eslint-disable-next-line no-use-before-define
            setPassGuide()
          }, 6000)
        }

        // const pLabel = document.getElementsByClassName('p-label')
        // pLabel[0].innerHTML = 'Cameras'
      })

      player.on('loadedmetadata', () => {
        if (videoInfo.stitched_video_url && (videoInfo.left_video_url || videoInfo.video_url)) {
          generateBarManualViewerBtn(player)
        }
        generateRateBtn(player)
        generateFullScreenBtn(player)

        // generateRateBtn(player)
        playerDuration.value = player.duration()
        addMarkers(highlightTime)
        loading.value = false
      })

      setTimeout(() => {
        videoLoaded.value = true
        addBtnActionsInsidePlayer(actionBtnsDom.value, camsContainerDom.value)
      }, 3000)

      player.on('touchstart', e => {
        console.log('mounted touchstart', e)
        clearInterval(touchInterval)
        if (String(e.target.id).includes('player_html5_api')) {
          console.log(e)
          clearTimeout(modeBtnTime)
          showModeBtn.value = !showModeBtn.value
          modeBtnTime = setTimeout(() => {
            showModeBtn.value = false
          }, 4000)
        }
        touchInterval = setInterval(() => {
          touchDuration.value += 1
        }, 10)
        if (manualViewer.value) {
          document.querySelectorAll('.vjs-touch-nav')[0].classList.remove('touch-show')
        }
      })

      player.on('touchend', () => {
        clearInterval(touchInterval)
        if (manualViewer.value) {
          // setPassGuide()
          // eslint-disable-next-line no-unused-expressions
          typeof DeviceMotionEvent !== 'undefined'
                && typeof DeviceMotionEvent.requestPermission === 'function'
                && DeviceMotionEvent.requestPermission()
                  .then(ev => {
                    // eslint-disable-next-line no-unused-expressions
                    ev === 'granted' && window.addEventListener('devicemotion', e => { console.log(e) })
                  })
                  .catch(console.error)

          if (touchDuration.value < 50) {
            document.querySelectorAll('.vjs-touch-nav')[0].classList.add('touch-show')
          }
          touchDuration.value = 0
        }
      })

      player.on('error', () => {
        console.log('error', player.error().message)
        sendUserActivities(params.user, null, player.error().message)
      })
    })

    return {
      // data
      newHighlight,
      manualViewer,
      startCreateHighlight,
      showInputName,
      errorMessages,
      validators: {
        required,
        emailValidator,
        minLengthValidator,
      },
      highlightName,
      currentTimeVideo,
      highlightSelected,
      showCustomControls,
      camSelected,
      inFullscreen,
      showModeBtn,
      videoLoaded,
      isLightMode,
      showGuide,
      loading,
      showDeleteHighlightDialog,
      showUnSaveHighlightDialog,

      // methods
      formatCurrency,
      defaultDownloadAppByOS,
      changeTo,
      isAndroidComputed,
      isIOSComputed,
      videoRNData,
      onStartHighlight,
      onStopHighlight,
      onSaveHighlight: onCreateHighlight,
      doAction,
      onSelectHighlight,
      addVideoId,
      includeVideoID,
      removeHighlight,
      onCancelHighlight,
      onSelectCam,
      onRemoveHighlight,
      onUnSaveHighlight,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,
      t,
      icons: {
        mdiClose,
        mdiBookmarkOutline,
        mdiExportVariant,
        mdiCheckBold,
        mdiTrashCanOutline,
        mdiPlay,
        mdiStop,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss';
@import '@core/preset/preset/pages/landing.scss';
</style>
<style lang="scss" scoped>
.absolute-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #222222;
  color: #ffffff6b;
  border-radius: 50px !important;
  padding: 2px;
}

.border-img {
  border-radius: 10px;
}

.text-mail {
  color: #FF3232;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.01875rem;
  text-decoration: none;
  white-space: nowrap;
}

.bg-grey {
  background-color: #979797;
}

.player-wrapper {
  width: 100vw;
  position: relative;
  min-height: 30dvh;
  margin-bottom: 0.5dvh;
}
</style>
<style class="vjs-styles-dimensions">
.player-dimensions {
  width: 100%;
  height: 100%;
}

.player-dimensions.vjs-fluid {
  padding-top: 56.25%;
  min-height: calc(21vw + 50vw / 1.118) !important;
}

.vjs-big-play-button {
  display: none;
}

.actionRow {
  position: absolute;
  top: 60%;
  z-index: 2;
  width: 100%;
}

.actionRow-one {
  position: absolute;
  top: 40%;
  z-index: 2;
  width: 100%;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.actionBtn {
  width: 34vw !important;
  height: 3.5vh !important;
  min-height: 35px !important;
  border-radius: 3vh !important;
  background-color: #FF3232 !important;
  color: #fff !important;
  opacity: 0.8 !important;
  max-width: 160px !important;
  font-family: "Open Sans", sans-serif;
  padding-inline: 5px;
  pointer-events: auto;
}

.actionBtn h5 {
  font-weight: bold !important;
  font-size: 14px !important;
}

.actionBtn-success {
  background-color: #14AE5C !important;
}

.video-js .vjs-time-control {
  display: block !important;
  padding-left: 1px;
  padding-right: 1px;
}

.vjs-button-vr {
  display: none !important;
}

.video-js .vjs-progress-control .vjs-progress-holder {
  margin-inline: 0px !important;
}

.inputContainer {
  margin: auto;
  width: 90%;
  /* padding: .5rem; */
  border-radius: 1em;
}

.actionRow-one:has(.inputContainer) {
  top: 5% !important;
  left: 10%;
  right: 10%;
}

.timer {
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  display: inline-block;
  width: 34vw;
  border-radius: 1rem;
  padding-top: 10px;
  margin-top: 10px;
  color: white;
  font-weight: bolder;
  max-width: 160px;
  min-height: 35px !important;
  font-size: 14px;
}

.video-js .vjs-slider {
  background-color: transparent !important
}

.vjs-playlist-nav {
  background: transparent !important;

}

.vjs-grid .vjs-arrow .vjs-prev,
.vjs-playlist-nav .vjs-prev,
.vjs-grid .vjs-arrow .vjs-next,
.vjs-playlist-nav .vjs-next {
  font-size: 25px;
}

.js-marker {
  position: absolute;
  background: #fff;
  width: 3px;
  height: 6px;
  top: 43%;
  z-index: 30;
  margin-left: -3px;
}

.vjs-480 .vjs-progress-holder .js-marker {
  top: 0px !important;
}

.js-marker:hover {
  opacity: 1;
}

.input-control>.v-input__slot {
  background: #58585b17;
}

.v-text-field__slot>input::placeholder {
  color: #9e9e9e !important
}

.vjs-control-bar>button.vjs-control.vjs-button.vjs-icon-vr {
  background-image: url('../../assets/images/svg/rotate-3d.svg') !important;
  background-size: contain !important;
  background-position-y: 0.5rem;
  background-position-x: 0.2rem;
  margin-right: 5px;
}

.vjs-control-bar>button.vjs-control.vjs-button.vjs-icon-cam {
  background-image: url('../../assets/images/svg/video-outline.svg') !important;
  background-size: contain !important;
  background-position-y: 0.4rem;
  background-position-x: 0.2rem;
  margin-right: 5px;
}

.vjs-control-bar>button.vjs-control.vjs-button.vjs-icon-1x {
  background-image: url('../../assets/images/svg/1x.svg') !important;
  background-size: inherit !important;
  background-position: center !important;
  margin-right: 5px;
}

.vjs-control-bar>button.vjs-control.vjs-button.vjs-icon-2x {
  background-image: url('../../assets/images/svg/2x.svg') !important;
  background-size: inherit !important;
  background-position: center !important;
  margin-right: 5px;
}

.vjs-control-bar>button.vjs-control.vjs-button.vjs-icon-fs {
  background-image: url('../../assets/images/svg/fullscreen.svg') !important;
  background-size: inherit !important;
  background-position: center !important;
  margin-right: 5px;
}

.vjs-control-bar>button.vjs-control.vjs-button.vjs-icon-fsExit {
  background-image: url('../../assets/images/svg/fullscreen-exit.svg') !important;
  background-size: inherit !important;
  background-position: center !important;
  margin-right: 5px;
}

.video-js .vjs-volume-panel.vjs-volume-panel-vertical {
  width: auto;
}

.video-js.vjs-fluid:not(.vjs-audio-only-mode),
.video-js.vjs-16-9:not(.vjs-audio-only-mode),
.video-js.vjs-4-3:not(.vjs-audio-only-mode),
.video-js.vjs-9-16:not(.vjs-audio-only-mode),
.video-js.vjs-1-1:not(.vjs-audio-only-mode) {
  height: auto;
}

.video-js .vjs-tech {
  outline: 0;
  width: 100%;
  height: 96% !important;
  display: inline-block;
  position: absolute;
  top: -20px !important;
  left: 0;
}

.video-js .vjs-touch-nav {
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  color: #fff;
  transition: visibility .4s, opacity .4s;
  display: flex;
  position: absolute;
  top: 40% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100% !important;
  justify-content: space-between;
  pointer-events: none !important;
}

.video-js .vjs-loading-spinner {
  top: 40% !important;
}

.cams-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  top: 5%;
  z-index: 2;
  width: 100%;
  padding-inline: 15%;

}

.cam-btn {
  border-radius: 30px;
  width: 80%;
  background-color: #000;
  padding: 6px 6px;
  min-width: 40px;
  opacity: 0.5;
  color: #fff;
  font-family: "Open Sans", sans-serif !important
}

.video-js .vjs-big-play-button {
  display: none !important;
}

.video-js .vjs-progress-control .vjs-play-progress {
  background-color: #FF3232;
  width: 0;
}

.video-js .vjs-load-progress div {
  background-color: #AAAAAA;
  opacity: 1;
}

.vjs-progress-control .vjs-slider:before {
  background: #999999 !important;
}

.video-js .vjs-replay-button {
  color: #fff;
  cursor: pointer;
  background: 0 0;
  border: 0;
  width: auto;
  display: none;
  position: absolute;
  top: 40% !important;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vjs-touch-inactive:not(.vjs-ad-playing):not(.vjs-audio):not(.vjs-has-mouse) .vjs-control-bar {
  opacity: 1 !important;
  visibility: visible !important;
}

.vjs-control-bar {

  bottom: 0px;
  /* top: 20px; */
}

.video-js {
  background-color: #000 !important;
}

.video-js .vjs-picture-in-picture-control,
.video-js .vjs-pip-control {
  display: none !important;
}

.video-js .vjs-time-control {
  min-width: 0em !important;
}

.avatar-highlight {
  width: 12vw !important;
  height: 12vw !important;
}

.vjs-time-divider {
  color: #000 !important;
}

.video-js .vjs-progress-control .vjs-load-progress,
.vjs-progress-control .vjs-play-progress {
  height: 6px !important;
}

.vjs-480 .vjs-control-bar .vjs-progress-control {
  width: 100%;
  margin-left: 0;
  position: absolute;
  top: -5px;
  left: 0;
  height: 40px;
}

.video-js .vjs-control-bar {
  bottom: 1px !important;
  left: 0;
  right: 0;
}

.player-wrapper-container {
  height: 100%;
}

@media screen and (min-width: 600px) {
  .player-wrapper-container {
    height: 100vh !important;
    overflow: hidden !important;
  }

  .video-js .vjs-tech {
    outline: 0 !important;
    width: 100% !important;
    height: 92% !important;
    display: inline-block !important;
    position: absolute !important;
    top: 0 !important;
    left: 0% !important;
  }

  #player>div.vjs-control-bar {
    inset-block-start: calc(100% - 20dvh) !important;

  }

  .player-dimensions.vjs-fluid {
    padding-top: 48.5% !important;
    min-height: 100dvh !important;
  }

  .video-js .vjs-time-divider {
    display: none !important
  }

  .video-js .vjs-touch-nav {
    padding-inline: 8% !important;
  }

  .video-js .vjs-progress-control .vjs-load-progress,
  .vjs-progress-control .vjs-play-progress {
    top: 43% !important;
  }
}

@media screen and (max-height: 348px) {

  #player>div.vjs-control-bar {
    bottom: 30vh !important;

  }

  .actionRow {
    top: 55% !important;
  }

}

.v-text-field--outlined .v-input__control .v-input__slot {
  background-color: #F2F2F2;
}

.v-text-field--outlined .v-input__control .v-input__slot input::placeholder {
  color: #7F7F7F !important;
}

.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: black !important;
}

.vjs-480 .vjs-progress-control .vjs-load-progress {
  background-color: #AAAA !important;
}

#player>div.vjs-control-bar>div.vjs-progress-control.vjs-control>div>div.vjs-load-progress>div:nth-child(n) {
  display: none;
}

/* .vjs-vr .vjs-b-r-b, .vjs-live .vjs-b-r-b, .vjs-ended .vjs-b-r-b, .vjs-ima-nonlinear .vjs-b-r-b, .vjs-vr .vjs-b-f-b, .vjs-live .vjs-b-f-b, .vjs-ended .vjs-b-f-b, .vjs-ima-nonlinear .vjs-b-f-b, .vjs-ima-nonlinear .vjs-brightness {
     display: inline-block !important;
} */

.vjs-has-started.vjs-user-inactive.vjs-playing:not(.vjs-touch-active):not(.vjs-ad-playing):not(.vjs-casting):not(.vjs-audio) .vjs-control-bar {
  pointer-events: auto !important;
}

.highlights-container {
  padding-bottom: 20dvh;
}

.vjs-touch-inactive:not(.vjs-ad-playing):not(.vjs-audio):not(.vjs-has-mouse) .vjs-control-bar {
  visibility: visible !important;
  opacity: 1 !important;
}

.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  visibility: visible;
  opacity: 1 !important;
  pointer-events: none;
  transition: visibility 1s, opacity 1s;
}

.vjs-auto-mute {
  display: none !important
}

.vjs-vr .vjs-b-r-b,
.vjs-live .vjs-b-r-b,
.vjs-ended .vjs-b-r-b,
.vjs-ima-nonlinear .vjs-b-r-b,
.vjs-vr .vjs-b-f-b,
.vjs-vr .vjs-b-p-b,
.vjs-live .vjs-b-f-b,
.vjs-ended .vjs-b-f-b,
.vjs-ima-nonlinear .vjs-b-f-b,
.vjs-ima-nonlinear .vjs-brightness {
  display: none !important;
}

.bg-light{
  background-color: #F2F2F2 !important;
}
.bg-item-list-light{
  background-color: #3f3f3f !important;
}
.icon-color-light{
  color: #3f3f3f;
}
.font-light1{
  color: #000 !important;
}
.font-light2{
  color: #3f3f3f !important;
}
.list-item-light{
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #d9d9d9 !important;
  padding-bottom: 1rem;
}
.col-center{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.v-card__subtitle, .v-card__text, .v-card__title {
    padding: 0px 0px !important;
}

.video-js .vjs-touch-nav.touch-show {
    opacity: 1 !important;
    visibility: visible;
    z-index: 999999999999 !important;
    pointer-events: all;

}
.list-content{
  padding-bottom: 20dvh
}

.video-js .vjs-progress-control .vjs-progress-holder {
  margin: 0px 0px 0px 0px !important;
}

.video-js .vjs-progress-holder .vjs-play-progress, .video-js .vjs-progress-holder .vjs-load-progress, .video-js .vjs-progress-holder .vjs-load-progress div {
    margin: 0px !important;
}

.user-guide-container{
  width: 100%;
  height: calc(100% - 44px);
  position: absolute;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.15);
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: all 2s;
}

.user-guide-description{
    text-align: center;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user-guide-description > h4{
  color: #F2F2F2;

}
.user-guide-icon{
  margin-top: 0.5dvh !important;
  margin-bottom: 1.5dvh!important;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease !important;
}

.v-enter-from,
.v-leave-to {
  opacity: 0 !important;
}
.loading-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999;
  background-color: #000;
}

.spinner {
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
    border-top-color: #ffffff80;
    border-radius: 50%;

  animation: spin 1s linear infinite;
margin-bottom: 13%;
  }

     @keyframes spin
     {
       to {
         transform: rotate(360deg);
       }
    }

    .vjs-rewind-control{
      display: none !important;
    }

    .video-js .vjs-current-time {
   min-width: 40px !important;
}
.video-js .vjs-big-button {
  pointer-events: auto !important;
}

</style>
